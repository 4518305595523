<template>
    <div class="manual-deposit text-light">
        <h3>
            Easily Deposit from SIM Toolkit, M-PESA APP, Safaricom App or USSD *334#
        </h3>
        <div class="info">
            Ensure you are depositing from your Registered Tucheze Phone Number
        </div>

        <ol>
            <li>Go to M-PESA Menu</li>
            <li>Select Lipa na M-PESA</li>
            <li>Select Paybill</li>
            <li>
                Enter the business number as <strong>575758</strong>
            </li>
            <li>
                Enter <strong>{{ profile?.msisdn }}</strong> as your
                account number.
            </li>
            <li>Enter the amount you want to transfer to your Tucheze account</li>
            <li>Enter your M-PESA PIN and send</li>
            <li>You will shortly receive an SMS confirming the transaction</li>
        </ol>
    </div>
</template>

<script>
export default {
    name: 'WalletManualDeposit',
    props: ['profile'],
   
}
</script>

<style scoped>
.manual-deposit {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.info {
    background: #E6F4FF;
    color: #8E9BA4;
    padding: 0.5rem;
    border-radius: 0.5rem;
}
h3 {
    font-weight: bold;
    font-size: 0.8rem !important;
}

ol {
    margin: 0;
    padding-left: 0.7rem;
    
}
</style>